import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import formsJson from "../../../../forms.json";
import CustomerForm from "../../../../components/Form";

class FormPay extends Component {
    state = {
        colorSelected: true,
    };

    handleColor = (e) => {
        if (e.target.value !== "") {
            this.setState({ ...this.state, colorSelected: false });
        } else {
            this.setState({ ...this.state, colorSelected: true });
        }
    };
    render() {
        const {
            global,
            validCounter,
            validateForm,
            reservationSeats,
            t,
            selectedFunction,
        } = this.props;
        let forms = formsJson[global.InformacionComprador - 1].rows;

        if(global.InformacionComprador === "4" && selectedFunction.Iso.toLowerCase() === "clp"){
            forms = forms.map((arr)=>{
                return arr.filter(n=>n.name!=="country")
            })
        }
        else if(global.InformacionComprador === "4" && selectedFunction.Iso.toLowerCase() !== "clp"){
            forms = forms.map((arr)=>{
                return arr.filter(n=>n.name!=="region")
            })
        }
        // forms.forEach((r) => {
        //     r.forEach((i) => {
        //         if (i.name === "email") {
        //             i.onBlur = (e) => {           
        //                 if (
        //                     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        //                         e.target.value
        //                     )
        //                 ) {
        //                   e.target.closest('.wrap-input').classList.add('error');
        //                 }else{
        //                   e.target.closest('.wrap-input').classList.remove('error');
        //                 }
        //             };
        //         }
        //     });
        // });
        return (
            <section className="content">
                <CustomerForm
                    title={true}
                    rowsFields={forms}
                    reservationSeats={reservationSeats}
                    validCounter={validCounter}
                    validateForm={validateForm}
                    subCultura={selectedFunction.SubCultura}
                    infoCom={global.InformacionComprador}
                />
            </section>
        );
    }
}

export default withTranslation()(FormPay);
